import { createIntersectionObserver } from "@solid-primitives/intersection-observer";
import { type Component, createSignal, type ParentProps } from "solid-js";

interface InfiniteScrollProps extends ParentProps {
  fetchNext?: () => void;
  shouldFetchMore: boolean;
  isFetching: boolean;
  id?: string;
  class?: string;
}

export const InfiniteScroll: Component<InfiniteScrollProps> = (props) => {
  const [ref, setRef] = createSignal<Element[]>([]);

  createIntersectionObserver(
    ref,
    (entries) => {
      if (!props.shouldFetchMore || props.isFetching) return;

      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          props.shouldFetchMore &&
          !props.isFetching
        ) {
          props.fetchNext?.();
        }
      });
    },
    {
      threshold: 1,
    },
  );

  return (
    <div class={props.class} id={props.id}>
      {props.children}
      {/* Using 5vh so that the bottom trigger for the infinite scroll happens with a threshold of 5% viewport height */}
      <div class="relative bottom-[5vh] z-20" ref={(e) => setRef([e])} />
    </div>
  );
};
