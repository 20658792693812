import { SolidMarkdown } from "solid-markdown";
import { twMerge } from "tailwind-merge";
import { type JSX, onMount, type Component } from "solid-js";
import hljs from "highlight.js";
import remarkGfm from "remark-gfm";
import type { DOMElement } from "solid-js/jsx-runtime";
import { CopyButton } from "~/components/buttons/copy/CopyButton";

const getDayOfWeek = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[new Date().getDay()] as string;
};
const getTimeOfDay = () => {
  const hour = new Date().getHours();

  if (hour >= 5 && hour < 12) {
    return "morning";
  }
  if (hour >= 12 && hour < 17) {
    return "afternoon";
  }
  if (hour >= 17 && hour < 21) {
    return "evening";
  }
  return "afternoon";
};

export const MarkdownRenderer: Component<{
  md: string;
  prefix: string;
  onMouseOverChild?: (
    e: MouseEvent & {
      currentTarget: HTMLElement;
      target: DOMElement;
    },
  ) => void;
  onMouseOutChild?: (
    e: MouseEvent & {
      currentTarget: HTMLElement;
      target: DOMElement;
    },
  ) => void;
}> = (props) => {
  const getKey = (index = "") => {
    return `${props.prefix};${index}`;
  };

  const selectableProps = (): JSX.HTMLAttributes<HTMLElement> => {
    return {
      //@ts-expect-error
      "data-selectable": true,
      onMouseOver: (e) => {
        props.onMouseOverChild?.(e);
      },
      onMouseOut: (e) => {
        props.onMouseOutChild?.(e);
      },
    };
  };

  const md = () =>
    props.md
      .replaceAll("${{CurrentDayOfWeek}}", getDayOfWeek())
      .replaceAll("${{CurrentTimeOfDayGreeting}}", getTimeOfDay());
  return (
    <SolidMarkdown
      remarkPlugins={[remarkGfm]}
      class="leading-relaxed"
      // Rendering dom elements as custom components to have more control over classes / extra behaviour (in the future)
      components={{
        hr: () => <hr class="my-4" />,
        h1: (props) => (
          <h1
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-4xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h1>
        ),
        h2: (props) => (
          <h2
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-3xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h2>
        ),
        h3: (props) => (
          <h3
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-2xl font-semibold mb-4 mt-6 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </h3>
        ),
        p: (props) => (
          <p
            data-block={getKey((props as unknown as { key: string }).key)}
            class="mb-2 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
          >
            {props.children}
          </p>
        ),
        ol: (props) => (
          <ol
            data-block={getKey((props as unknown as { key: string }).key)}
            start={props.start}
            class="list-outside list-decimal ml-4 sm:ml-6 mt-1 mb-4 scroll-mt-32 break-words"
          >
            {props.children}
          </ol>
        ),
        ul: (props) => (
          <ul
            data-block={getKey((props as unknown as { key: string }).key)}
            class="list-outside list-disc ml-4 sm:ml-6 mt-1 mb-4 scroll-mt-32 break-words"
          >
            {props.children}
          </ul>
        ),
        li: (props) => (
          <li
            class="ml-2 mt-2 scroll-mt-32 break-words"
            // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
            tabIndex={0}
            {...selectableProps}
            data-block={getKey((props as unknown as { key: string }).key)}
          >
            {props.children}
          </li>
        ),
        a: (props) => (
          <a
            data-block={getKey((props as unknown as { key: string }).key)}
            class="text-violet-500 dark:text-violet-400 underline underline-offset-2 scroll-mt-32 break-words"
            href={props.href}
            target="_blank"
            rel="noreferrer"
          >
            {props.children}
          </a>
        ),
        pre: (props) => {
          let ref!: HTMLPreElement;

          const languageClass = () => {
            if (Array.isArray(props.node.properties?.className)) {
              const className = props.node.properties.className
                .find((c) => c.toString().startsWith("language"))
                ?.toString();
              if (className) return className;
            }
            const code = props.node.children.find(
              (c) => c.type === "element" && c.tagName === "code",
            );
            if (
              code &&
              "properties" in code &&
              Array.isArray(code.properties?.className)
            ) {
              const className = code.properties.className
                .find((c) => c.toString().startsWith("language"))
                ?.toString();
              if (className) return className;
            }
          };
          onMount(() => {
            hljs.highlightElement(ref);
          });
          return (
            <div class="rounded border border-indigo-900 mb-4">
              <div class="rounded-t flex justify-end bg-indigo-1000 dark:bg-indigo-1100/70 pr-1 pt-1">
                <CopyButton
                  class="bg-white dark:bg-indigo-800"
                  content=""
                  label="Copy"
                  onClick={async () => {
                    await navigator.clipboard.writeText(ref.innerText);
                  }}
                />
              </div>
              <pre
                data-block={getKey((props as unknown as { key: string }).key)}
                ref={ref}
                class={twMerge(
                  "overflow-auto p-4 rounded-b scroll-mt-32",
                  props.class,
                  languageClass() || false,
                )}
              >
                <code>{props.children}</code>
              </pre>
            </div>
          );
        },
        table: (props) => {
          return (
            <div
              data-block={getKey((props as unknown as { key: string }).key)}
              class="max-w-full overflow-x-auto border border-slate-100 dark:border-indigo-900 rounded bg-white/30 dark:text-white dark:bg-indigo-1100/70 mb-6 scroll-mt-32"
            >
              <table class="w-full table-automin-w-full divide-y divide-slate-100 dark:divide-indigo-900">
                {props.children}
              </table>
            </div>
          );
        },
        th: (props) => (
          <th
            data-block={getKey((props as unknown as { key: string }).key)}
            class="p-2 text-left text-sm font-semibold text-slate-900 dark:text-white scroll-mt-32"
          >
            {props.children}
          </th>
        ),
        tbody: (props) => (
          <tbody
            data-block={getKey((props as unknown as { key: string }).key)}
            class="divide-y divide-slate-100 dark:divide-indigo-900 scroll-mt-32"
          >
            {props.children}
          </tbody>
        ),
        td: (props) => (
          <td
            data-block={getKey((props as unknown as { key: string }).key)}
            class="whitespace-nowrap px-3 py-4 text-sm text-slate-800 dark:text-indigo-100 scroll-mt-32"
          >
            {props.children}
          </td>
        ),
      }}
    >
      {md()}
    </SolidMarkdown>
  );
};
