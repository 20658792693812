import { stAnalytics } from "@repo/analytics";
import { modelDisplayName, type threads } from "@repo/client";
import { debounce } from "@solid-primitives/scheduled";
import {
  TbEyeCog,
  TbHelpCircle,
  TbLanguage,
  TbPencil,
  TbSearch,
  TbShare3,
  TbSparkles,
} from "solid-icons/tb";
import {
  type Accessor,
  type Component,
  For,
  Show,
  batch,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";
import type { DOMElement } from "solid-js/jsx-runtime";
import { Motion } from "solid-motionone";
import { twMerge } from "tailwind-merge";
import { StButton } from "~/components/buttons";
import { CopyButton } from "~/components/buttons/copy/CopyButton";
import { StDropdown } from "~/components/popups/StDropdown";
import { Tooltip, TooltipTrigger } from "~/components/popups/Tooltip";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import {
  type AuthenticatedIdentity,
  isAuthenticatedIdentity,
} from "~/domains/identity/types";
import { PromptActionsBar } from "~/domains/threads/components/bars";
import { responsePromptsData } from "~/lib/data";
import { languagesByPopularity } from "~/lib/data/lanugagesByPopularity";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";
import { TextSelectionMenu } from "../TextSelectionMenu";
import { MarkdownRenderer } from "./MarkdownRenderer";
import "./codeHighlightTheme.css";

export type TextInstructionUnitV1Props = {
  message: threads.MessageTextV1;
  class?: string;
  disableActions?: boolean;
};

export const TextUnitV1 = (props: TextInstructionUnitV1Props) => {
  const { editor, typeText } = usePromptContext();
  const [ref, setRef] = createSignal<HTMLElement>();

  return (
    <Motion.div
      // {...getUnitAnimationConfig()}
      class="dark:text-slate-200 text-gray-800 text-sm md:text-base scroll-mt-32"
      data-block={props.message.messageId}
    >
      <div
        ref={setRef}
        class={twMerge("flex flex-col break-words", props.class)}
      >
        <TextUnitV1Markdown message={props.message} />
      </div>

      <TextUnitV1TextSelection ref={ref} />

      <Show when={!props.disableActions}>
        <TextUnitV1ActionsBar message={props.message} />

        <Show when={props.message.textSuggestions}>
          <div>
            <span class="text-[10px] text-violet-500 dark:text-violet-400 underline underline-offset-4 uppercase tracking-wider">
              Prompt Suggestions:
            </span>
            <ul class="block list-disc ml-4 pt-2">
              <For each={props.message.textSuggestions}>
                {(suggestion) => (
                  <li class="dark:text-purple-400 text-black pl-2 block text-left mb-4">
                    <button
                      class="text-left hover:underline underline-offset-2"
                      type={"button"}
                      onClick={() => {
                        editor()?.commands.focus();
                        typeText(suggestion);
                      }}
                    >
                      {suggestion}
                    </button>
                  </li>
                )}
              </For>
            </ul>
          </div>
        </Show>
      </Show>
    </Motion.div>
  );
};

const TextUnitV1Markdown: Component<{
  message: threads.MessageTextV1;
}> = (props) => {
  const [el, setEl] = createSignal<HTMLElement>();
  const [domRect, setDomRect] = createSignal<DOMRect>();

  // Use prevent hide to keep the dropdown alive when a user
  // exits all markdown elements but is hovering the mouse on the dropdown
  let preventHide = false;
  // Used to block the dropdown from showing when the text selction dropdown is out
  let preventShow = false;

  // Debouncing it to prevent flickering when switching in between elements fast
  const setRectDebounced = debounce(setDomRect, 500);

  // The hover text modal is different from
  onMount(() => {
    const listener = () => {
      const selection = getSelection();
      if ((selection?.toString() || "") !== "") {
        setRectDebounced.clear();
        setDomRect();
        preventShow = true;
      } else {
        preventShow = false;
      }
    };
    document.addEventListener("selectionchange", listener);
    onCleanup(() => {
      document.removeEventListener("selectionchange", listener);
    });
  });

  const onHover = (
    e: MouseEvent & {
      target: DOMElement;
    },
  ) => {
    if (preventShow) return;
    if (!(e.target instanceof HTMLElement)) return;
    setEl(e.target);
    setRectDebounced.clear();
    const elRect = e.target.getBoundingClientRect();

    const messageEl = document.querySelector(
      `[data-block="${props.message.messageId}"]`,
    );
    const messageRect = messageEl?.getBoundingClientRect() ?? elRect;

    setRectDebounced({
      top: elRect.top,
      height: elRect.height,
      bottom: elRect.bottom,
      y: elRect.y,
      left: messageRect.left,
      right: messageRect.right,
      width: messageRect.width,
      x: messageRect.x,
      toJSON: elRect.toJSON,
    });
  };

  const onUnhover = () => {
    if (preventHide) return;
    setRectDebounced(undefined);
  };

  return (
    <>
      <TextSelectionMenu
        rect={domRect()}
        from="block"
        animatedMovement
        opts={{
          preventScroll: false,
          placement: "right-start",
          hideWhenDetached: true,
        }}
        content={{
          onMouseEnter: () => {
            preventHide = true;
            setRectDebounced.clear();
          },
          onMouseLeave: (e) => {
            if ("toElement" in e && e.toElement instanceof HTMLElement) {
              const id = e.toElement.id;
              if (!id.includes("dropdownmenu")) {
                preventShow = true;
                preventHide = false;
                onUnhover();
                setTimeout(() => {
                  preventShow = false;
                }, 510);
                return;
              }
            }

            preventHide = false;
            onUnhover();
          },
        }}
        getText={() => el()?.innerText || ""}
        getEl={el}
      />

      <MarkdownRenderer
        prefix={props.message.messageId}
        md={props.message.parts.join("\n")}
        onMouseOverChild={onHover}
        onMouseOutChild={onUnhover}
      />
    </>
  );
};

// Handles selecting
const TextUnitV1TextSelection: Component<{
  ref: Accessor<HTMLElement | undefined>;
}> = (props) => {
  const [selection, setSelection] = createSignal<Selection>();
  const [domRect, setDomRect] = createSignal<DOMRect>();

  const resetPanel = () => {
    batch(() => {
      setSelection();
      setDomRect();
    });
  };

  const listener = debounce(() => {
    const selection = getSelection();

    if (!selection) return resetPanel();
    if (
      !props.ref()?.contains(selection.anchorNode) ||
      !props.ref()?.contains(selection.focusNode)
    )
      return resetPanel();
    if (selection.rangeCount === 0 || selection.isCollapsed)
      return resetPanel();
    batch(() => {
      setSelection(selection);
      setDomRect(selection.getRangeAt(0).getBoundingClientRect());
    });
  }, 300);

  onMount(() => {
    document.addEventListener("selectionchange", listener);
    onCleanup(() => {
      document.removeEventListener("selectionchange", listener);
    });
  });

  return (
    <TextSelectionMenu
      rect={domRect()}
      from="select"
      getText={() => selection()?.toString() || ""}
      getEl={() => selection()?.anchorNode as HTMLElement | undefined}
    />
  );
};

const TextUnitV1ActionsBar: Component<{
  message: threads.MessageTextV1;
}> = (props) => {
  const wire = useWire();
  const { submitPrompt } = usePromptContext();

  const diagnosticsURL = async () => {
    await wire.services.threads.getSignedURLForMessageDiagnostics(
      props.message.messageId,
    );
  };
  const showDiagnosticsBtn = () => {
    const ident = wire.services.identity.snapshot.context.identity;
    if (isAuthenticatedIdentity(ident)) {
      return ident.emailVerified && ident.email.endsWith("@storytell.ai");
    }
    return false;
  };

  return (
    <PromptActionsBar class="justify-start gap-2 pt-6">
      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[
          {
            kind: "item",
            content: "Link to this response",
            props: {
              class: "px-2",
              onSelect: () => {
                const projectId =
                  wire.services.identity.snapshot.context.identity
                    .workingContext.projectId;
                const threadId =
                  wire.services.threads.snapshot.context.threadId;
                navigator.clipboard.writeText(
                  window.location.origin +
                    urls.thread(
                      projectId,
                      threadId ?? "",
                      encodeURIComponent(props.message.messageId),
                    ),
                );
              },
            },
          },
          {
            kind: "item",
            content: "Share via email",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                open(
                  responsePromptsData.shareViaEmailMailtoLink({
                    text,
                    threadName:
                      wire.services.threads.snapshot.context.label ||
                      "a thread",
                    threadUrl: `https://storytell.ai${urls.thread(
                      wire.services.identity.snapshot.context.identity
                        .workingContext.projectId,
                      wire.services.threads.snapshot.context.threadId || "",
                    )}`,
                    userDisplayName: (
                      wire.services.identity.snapshot.context
                        .identity as AuthenticatedIdentity
                    ).displayName,
                  }),
                );
              },
            },
          },
        ]}
      >
        <StButton icon={TbShare3}>Share</StButton>
      </StDropdown>

      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[
          {
            kind: "item",
            content: "Explain this to me like I'm five",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.explainLikeImFive(text),
                  mentionedAssets: [],
                });
              },
            },
          },
          {
            kind: "item",
            content: "Why does this matter?",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.whyDoesThisMatter(text),
                  mentionedAssets: [],
                });
              },
            },
          },
        ]}
      >
        <StButton simple icon={TbHelpCircle}>
          Understand
        </StButton>
      </StDropdown>
      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[
          {
            kind: "item",
            content: "Get more detail on this",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.getMoreDetail(text),
                  mentionedAssets: [],
                });
              },
            },
          },
          {
            kind: "item",
            content: "Find similar content like this",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.findMoreLikeThis(text),
                  mentionedAssets: [],
                });
              },
            },
          },
        ]}
      >
        <StButton simple icon={TbSearch}>
          Go deeper
        </StButton>
      </StDropdown>
      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        theme="invert"
        items={[
          {
            kind: "item",
            content: "For brevity",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.rewriteForBrevity(text),
                  mentionedAssets: [],
                });
              },
            },
          },
          {
            kind: "item",
            content: "With more depth",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.rewriteForDepth(text),
                  mentionedAssets: [],
                });
              },
            },
          },
          {
            kind: "item",
            content: "For a less technical audience",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.rewriteForLessTechnical(text),
                  mentionedAssets: [],
                });
              },
            },
          },
          {
            kind: "item",
            content: "For a more technical audience",
            props: {
              class: "px-2",
              onSelect: () => {
                const text = props.message.parts.join("\n");
                submitPrompt({
                  text: responsePromptsData.rewriteForMoreTechnical(text),
                  mentionedAssets: [],
                });
              },
            },
          },
        ]}
      >
        <StButton simple icon={TbPencil}>
          Rewrite
        </StButton>
      </StDropdown>
      <StDropdown
        opts={{
          placement: "top",
        }}
        trigger={{
          as: "span",
        }}
        containerClass="max-h-60 overflow-y-auto"
        theme="invert"
        items={languagesByPopularity.slice(0, 50).map((l) => ({
          kind: "item",
          content: `to ${l.name}`,
          props: {
            class: "px-2",
            onSelect: () => {
              const text = props.message.parts.join("\n");
              submitPrompt({
                text: responsePromptsData.translate(l.name, text),
                mentionedAssets: [],
              });
            },
          },
        }))}
      >
        <StButton simple icon={TbLanguage}>
          Translate
        </StButton>
      </StDropdown>

      <Tooltip
        theme="invert"
        placement="top"
        content={
          <p class="max-w-80">
            {`Answered with ${modelDisplayName(
              props.message.provenance.processedByModel,
            )}${
              props.message.provenance.transformationId
                ? ` and enhanced by Storytell for the ${props.message.provenance.transformationId} use case`
                : ""
            } using ${
              props.message.provenance.inputTokens +
              props.message.provenance.outputTokens
            } tokens in a secure, encrypted enterprise connection.`}{" "}
            <a
              target="_blank"
              href="http://go.storytell.ai/enterprise-connection"
              class="underline underline-offset-2 whitespace-pre"
              rel="noreferrer"
            >
              [Learn More]
            </a>
          </p>
        }
      >
        <TooltipTrigger>
          <StButton simple icon={TbSparkles}>
            {modelDisplayName(props.message.provenance.processedByModel)}
          </StButton>
        </TooltipTrigger>
      </Tooltip>
      <div class="flex gap-2 flex-grow justify-end">
        <CopyButton
          label="Copy Answer"
          onClick={() => {
            stAnalytics.track("thread_response_copied", undefined);
          }}
          content={props.message.parts.join("\n\n")}
        />
        <Show when={showDiagnosticsBtn()}>
          <StButton
            simple
            label="Copy Message Diagnostics Link"
            icon={TbEyeCog}
            onClick={diagnosticsURL}
          />
        </Show>
      </div>
    </PromptActionsBar>
  );
};
