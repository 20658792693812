import type { Component } from "solid-js";
import { StIcon } from "../icons";
import { twMerge } from "tailwind-merge";
import { TbLoader2 } from "solid-icons/tb";

export const Spinner: Component<{ class?: string }> = (props) => (
  <StIcon
    icon={TbLoader2}
    class={twMerge("animate-spin dark:text-white", props.class)}
  />
);
