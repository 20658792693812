export const responsePromptsData = {
  explainLikeImFive: (text: string) => `Based on the content I will share I want you to focus your answers on analyzing just my content + inputs so I can get as personalized a response back as possible.
Respond with: “I’m taking a look at what you’ve shared. Here’s my attempt to break down the concept of [input the provided concept] to a five-year-old.” Then proceed with a response as follows: You are tasked with explaining a concept in a simple, easy-to-understand manner, as if you were explaining it to fifth grader, which is the level that the newspaper USA Today is written in. Your goal is to make complex ideas accessible without losing the essence of the concept. Follow these guidelines to create your explanation:
1. Simplify the concept: Break down complex ideas into their most basic components. Avoid jargon or technical terms unless absolutely necessary.
2. Use concrete examples: Relate the concept to everyday objects, experiences, or situations that a fifth grader might be familiar with. Fifth graders are around 10-12 years old.
3. Use analogies: Compare the concept to something a fifth grader already understands. Keep in mind that fifth graders have rich internal lives, emotionally and intellectually. So don’t see your reader short!
4. Keep it brief: Limit your explanation to a few short paragraphs. Humans have shorter attention spans these days thanks to social media: so concise explanations are more effective.
5. Use language that matches your audience: Adjust your vocabulary and sentence structure to match the target age group.
6. Engage the imagination: Use descriptive language that helps a fifth grader visualize the concept.
7. Avoid oversimplification: While simplifying, ensure that the core idea remains accurate and isn't misrepresented. Please provide your explanation within \u003cexplanation\u003e tags.
Begin with a brief introduction that captures the reader’s attention, then proceed with your simplified explanation of the concept. Use the provided content as the concept to explore unless you get different instructions from the user.

Content to explain: "${text}"`,

  whyDoesThisMatter: (text: string) => `I need more context about why this is important; why it matters. Please help me understand why the exerpt below is relevant, as follows:

- What related material does this reference that would give me mroe context about this?
- What other hidden insights or connections can you help me make to this content that will help me understand it?

Begin your answer with "Here's more context about why this matters:" and then share your answer.

Here's the excerpt in question that I need the context around:

${text}`,

  getMoreDetail: (text: string) =>
    `Provide more detail on this excerpt: "${text}" 

Here's how I'd like to get this additional deal:
- Go as in-depth as you can. I want as many specifics as you can find that relate to the excerpt above.

- Start your response with "Sure, here's more detail as you requested:"

- Then, provide an executive summary of the additional detail you found. Title it "Summary of additional details:"

- After the executive summary, create a bullet point list of the additional details you found. For each bullet point, bold the title of the additional detail, followed by a description of that detail. Title this lis "Specific points:"

- After the bullet point list, write out as much detail as you can offer. This section can be as long as necessary -- more detail is beter. Title this section "Deeper dive on additional details:"
`,

  findMoreLikeThis: (text: string) =>
    `I want to find all the content that is similar to what's below. When you share the similar content with me, create two sections. Title the first section "Similar content from knowledge powering this thread" and only provide content that is contained in the body of content I've shared.  Strive to provide at least five examples, but if you can find 10, 20 or more, please do. All examples should have similarity scores of 50% or higher.

Title the second section "Similar content from the world's knowledge" and look for similar content that is not from the content I've shared, but that you know is similar from your knowledge training set.

When you share the results of both sections do so in bullet points. For each bullet point, do the following: List a short summary of the similar content in bold, followed by a detailed description. Put a sub bullet point with a "Similarity score" and rate from a scale of 0% (not at all similar) to 100% (identical) how similar this content is to the original text below. Next, put a second sub bullet point titled "How this content is similar:" and describe why you chose to list this content as similar to the original text below. 

Once you are finished, ask me if I'd like you to find more similar content, and if so, repeat the process above to find more results.

${text}`,

  rewriteForBrevity: (text: string) => `Rewrite the following text with a focus on brevity by bolding the main concept in your response and returning no more than a one-paragraph executive summary. 
Begin your response with "Here's my last answer re-written for brevity:"

${text}`,

  rewriteForDepth: (text: string) => `Rewrite the following text with a focus on depth. I want as many specifics as you can provide. 
Begin your response with "Here's my last answer re-written with more depth:"

${text}`,

  rewriteForLessTechnical: (text: string) => `Rewrite the following text for a non-technical audience. Use vivid imagery, analogies and metaphors where applicable to help this audience understand the material:
Begin your response with "Here's my last answer re-written for a less technical audience:"

${text}`,

  rewriteForMoreTechnical: (text: string) => `Rewrite the following text for a more technical audience. Provide as much technical depth and detail as possible: 
Begin your response with "Here's my last answer re-written for a more technical audience:"

${text}`,

  shareViaEmailMailtoLink: ({
    text,
    threadName,
    threadUrl,
    userDisplayName,
  }: {
    text: string;
    threadName: string;
    userDisplayName: string;
    threadUrl: string;
  }) => {
    const subject = `Storytell snippet from ${threadName}`;
    const body = `${userDisplayName} is sharing this snippet with you. You can read then entire thread at ${threadUrl}

=================
${text}
=================

Learn more at https://Storytell.ai`;

    return `mailto:?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
  },

  translate: (language: string, text: string) =>
    `Translate this into ${language}: ${text}`,
};
