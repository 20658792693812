import { stAnalytics } from "@repo/analytics";
import { A, useNavigate } from "@solidjs/router";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
  TbUserPlus,
  TbX,
} from "solid-icons/tb";
import {
  type JSX,
  type JSXElement,
  type ParentComponent,
  Show,
  Suspense,
  createEffect,
  on,
} from "solid-js";
import { twMerge } from "tailwind-merge";
import Logo from "~/assets/Logo";
import LogoNoText from "~/assets/LogoNoText";
import { UpcomingFeature } from "~/components/UpcomingFeature";
import { UserAvatarWithMenu } from "~/components/UserAvatarWithMenu";
import { StButton } from "~/components/buttons";
import { Bug } from "~/components/dev";
import {
  LEFT_SIDEBAR_SIZE,
  RIGHT_SIDEBAR_SIZE,
} from "~/domains/layouts/appLayout";
import { BackgroundBlobLeft } from "~/domains/marketing/components/BackgroundBlobLeft";
import { BackgroundBlobRight } from "~/domains/marketing/components/BackgroundBlobRight";
import { NewThreadIdIndicator } from "~/domains/threads";
import { urls } from "~/lib/urls";
import { useBreakpoints } from "~/lib/useBreakPoints";
import { useWire } from "~/wire";

const TOPBAR_SIZE = "64px";
const CONTENT_SIZE = `calc(100vh - ${TOPBAR_SIZE})`;

export const AppLayout: ParentComponent<{
  topBarMain?: JSXElement;
  leftSidebar?: JSXElement;
  rightSidebar?: JSXElement;
  mainContent?: JSXElement;
}> = (props) => {
  const wire = useWire();
  const { appLayout } = wire.services;
  return (
    <Show when={!wire.metadata.isDefault}>
      <Suspense>
        <Bug />

        <div
          class="grid w-screen"
          style={{
            "grid-template-rows": `${TOPBAR_SIZE} ${CONTENT_SIZE}`,
            "grid-template-columns": "100vw",
          }}
        >
          {/* Background blobs */}
          <div class="col-start-1 col-end-2 row-start-1 row-end-3 pointer-events-none">
            <div class="overflow-hidden w-full h-full relative">
              <BackgroundBlobLeft />
              <BackgroundBlobRight />
            </div>
          </div>

          {/* First Row - TOP BAR */}
          <div
            class="grid row-start-1 row-end-2 col-start-1 col-end-2"
            style={{
              "grid-template-columns": "max-content 1fr max-content",
            }}
          >
            {/* Navbar bottom border, needs to be div for the gradient */}
            <div
              aria-hidden
              class="row-start-1 row-end-2 col-start-1 col-end-4 pointer-events-none flex flex-col justify-end"
            >
              <div class="bg-gradient-to-r h-[2px] from-transparent via-violet-300/20 to-transparent" />
            </div>

            {/* Storytell logo */}
            <div class="row-start-1 row-end-2 col-start-1 col-end-2 dark:text-white flex items-center h-full px-6">
              <A href="/" aria-label="storytell">
                <span class="hidden md:block">
                  <Logo />
                </span>
                <span class="md:hidden">
                  <LogoNoText />
                </span>
              </A>
            </div>

            {props.topBarMain}

            <div class="row-start-1 row-end-2 col-start-3 col-end-4">
              <TopBarActions />
            </div>
          </div>

          {/* Second Row - MAIN CONTENT */}
          <div
            class="md:grid row-start-2 row-end-3 col-start-1 col-end-2 w-screen overflow-hidden"
            classList={{
              "transition-all": appLayout.data.enableEasing,
            }}
            style={{
              "grid-template-columns": `${appLayout.data.leftSidebar.size}px 1fr ${appLayout.data.rightSidebar.size}px`,
            }}
          >
            {/* Left sidebar border */}
            <div
              aria-hidden
              class="hidden md:flex pointer-events-none col-start-1 col-end-2 row-start-1 row-end-2 justify-end"
            >
              <div class="bg-gradient-to-b h-full w-[2px] from-transparent via-violet-300/20 to-transparent" />
            </div>
            {/* Right sidebar border */}
            <div
              aria-hidden
              class="hidden md:block pointer-events-none col-start-3 col-end-4 row-start-1 row-end-2"
            >
              <div class="bg-gradient-to-b h-full w-[2px] from-transparent via-violet-300/20 to-transparent" />
            </div>

            {/* Used for resizing the left sidebar */}
            <div
              class="hidden md:block col-start-2 col-end-3 row-start-1 row-end-2 w-2 relative -left-1 cursor-ew-resize"
              {...appLayout.resizeDOMProps("leftSidebar")}
            />

            {/* Used for resizing the right sidebar */}
            <div
              class="hidden md:block col-start-3 col-end-4 row-start-1 row-end-2 w-2 relative -left-1 cursor-ew-resize"
              {...appLayout.resizeDOMProps("rightSidebar")}
            />

            {props.leftSidebar}
            {props.mainContent}
            {props.rightSidebar}

            {props.children}
          </div>
        </div>
      </Suspense>
    </Show>
  );
};

export const AppLayoutTopbarMain: ParentComponent<
  JSX.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return (
    <div
      class={twMerge(
        "row-start-1 row-end-2 col-start-2 col-end-3",
        props.class,
      )}
    >
      {props.children}
    </div>
  );
};

export const AppLayoutLeftSidebar: ParentComponent<
  JSX.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const wire = useWire();
  const { data, setData } = wire.services.appLayout;

  // Auto-close sidebar on mobile
  const breakpoints = useBreakpoints();
  createEffect(
    on(
      () => breakpoints.md,
      (md) => {
        if (!md) {
          setData("leftSidebar", { closed: true, size: 0 });
        }
      },
    ),
  );

  return (
    <>
      <div
        class={twMerge(
          "fixed left-0 top-0 bottom-0 transition-all z-50 bg-white dark:bg-indigo-1100 md:bg-transparent md:dark:bg-transparent md:static col-start-1 col-end-2 row-start-1 row-end-2",
          props.class,
          "overflow-x-hidden overflow-y-auto",
        )}
        classList={{
          "w-0 md:w-auto": data.leftSidebar.closed,
          "w-screen md:w-auto": !data.leftSidebar.closed,
        }}
      >
        <div class="flex justify-end p-2 pb-0 md:hidden">
          <StButton
            icon={TbX}
            onClick={() => setData("leftSidebar", { closed: true, size: 0 })}
          />
        </div>
        {props.children}
      </div>
      <div
        class="fixed top-[72px] z-40 flex justify-end pl-2 mb-2"
        classList={{
          "transition-all": data.enableEasing,
        }}
        style={{
          left: `${data.leftSidebar.size}px`,
        }}
      >
        <StButton
          class="text-indigo-600 md:bg-transparent"
          label={
            data.leftSidebar.closed ? "Expand sidebar" : "Collapse sidebar"
          }
          size={breakpoints.md ? "md" : "sm"}
          icon={
            data.leftSidebar.closed
              ? TbLayoutSidebarLeftExpand
              : TbLayoutSidebarLeftCollapse
          }
          onClick={() =>
            setData("leftSidebar", (s) => ({
              closed: !s.closed,
              size: s.closed ? LEFT_SIDEBAR_SIZE : 0,
            }))
          }
        >
          <Show when={!breakpoints.md}>
            <span>Threads</span>
          </Show>
        </StButton>
      </div>
    </>
  );
};
export const AppLayoutRightSidebar: ParentComponent<
  JSX.HTMLAttributes<HTMLDivElement>
> = (props) => {
  const wire = useWire();
  const { data, setData } = wire.services.appLayout;

  // Auto-close sidebar on mobile
  const breakpoints = useBreakpoints();
  createEffect(
    on(
      () => breakpoints.md,
      (md) => {
        if (!md) {
          setData("rightSidebar", { closed: true, size: 0 });
        }
      },
    ),
  );

  return (
    <>
      <div
        class="fixed top-[72px] z-40 pr-2 mb-2"
        classList={{
          "transition-all": data.enableEasing,
        }}
        style={{
          right: `${data.rightSidebar.size}px`,
        }}
      >
        <StButton
          class="text-indigo-600 md:bg-transparent"
          label={
            data.rightSidebar.closed ? "Expand sidebar" : "Collapse sidebar"
          }
          size={breakpoints.md ? "md" : "sm"}
          iconPlacement="right"
          icon={
            data.rightSidebar.closed
              ? TbLayoutSidebarRightExpand
              : TbLayoutSidebarRightCollapse
          }
          onClick={() =>
            setData("rightSidebar", (s) => ({
              closed: !s.closed,
              size: s.closed ? RIGHT_SIDEBAR_SIZE : 0,
            }))
          }
        >
          <Show when={!breakpoints.md}>
            <span>Knowledge</span>
          </Show>
        </StButton>
      </div>
      <div
        class={twMerge(
          "fixed right-0 top-0 bottom-0 transition-all z-50 bg-white dark:bg-indigo-1100 md:bg-transparent md:dark:bg-transparent md:static col-start-3 col-end-4 row-start-1 row-end-2",
          props.class,
          "overflow-x-hidden overflow-y-auto",
        )}
        classList={{
          "w-0 md:w-auto": data.rightSidebar.closed,
          "w-screen md:w-auto": !data.rightSidebar.closed,
        }}
      >
        <div class="flex justify-end p-2 pb-0 md:hidden">
          <StButton
            icon={TbX}
            onClick={() => setData("rightSidebar", { closed: true, size: 0 })}
          />
        </div>
        {props.children}
      </div>
    </>
  );
};

export const AppLayoutMainContent: ParentComponent<
  JSX.HTMLAttributes<HTMLDivElement>
> = (props) => {
  return (
    <div
      class={twMerge(
        "h-full col-start-2 col-end-3 row-start-1 row-end-2",
        props.class,
      )}
    >
      {props.children}
    </div>
  );
};

const TopBarActions = () => {
  const wire = useWire();
  const identityService = wire.services.identity;
  const navigate = useNavigate();
  const onNewThread = async () => {
    if (!identityService.snapshot.context.identity.isAuthenticated) {
      return;
    }
    const projectId =
      identityService.snapshot.context.identity.workingContext.projectId;
    navigate(urls.thread(projectId, NewThreadIdIndicator));
  };

  return (
    <div class="flex items-center gap-2 h-full px-6">
      <div class="hidden sm:block">
        <UpcomingFeature label="Top Navigation Invite Button">
          <StButton
            label="Invite collaborators"
            icon={TbUserPlus}
            onClick={() => {
              stAnalytics.track("thread_invite_clicked", undefined);
            }}
          >
            Invite
          </StButton>
        </UpcomingFeature>
      </div>

      <Show
        when={
          wire.services.identity.snapshot.context.identity.isAuthenticated &&
          !wire.services.identity.snapshot.context.identity.isGuest
        }
        fallback={
          <>
            <A
              href="/auth/login"
              onClick={() => {
                stAnalytics.track("log_in_clicked", undefined);
              }}
              class="text-sm font-semibold leading-6 dark:text-slate-300 mr-2 underline underline-offset-2"
            >
              Log in
            </A>

            <StButton
              onClick={() => {
                stAnalytics.track("sign_up_clicked", undefined);
              }}
              href="/auth/signup"
              size="lg"
              class="px-5 text-nowrap"
            >
              Sign up
            </StButton>
          </>
        }
      >
        <UserAvatarWithMenu />
      </Show>
    </div>
  );
};
