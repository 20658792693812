import { Motion } from "solid-motionone";
import { twMerge } from "tailwind-merge";
import { AvatarSize, CircularAvatar } from "~/components/avatars";
import { Show, createSignal } from "solid-js";
import { pointerHover } from "@solid-primitives/pointer";
import { createElementSize } from "@solid-primitives/resize-observer";
import { useBreakpoints } from "~/lib/useBreakPoints";
import type { threads } from "@repo/client";
import { CopyButton } from "~/components/buttons/copy/CopyButton";
import { stAnalytics } from "@repo/analytics";

// place this in code to avoid being tree-shaken
pointerHover;

export type TextInstructionUnitV1Props = {
  message: threads.MessagePromptV1;
  class?: string;
};

export const TextPromptUnitV1 = (props: TextInstructionUnitV1Props) => {
  const MAX_LEN = 340;
  const [ref, setRef] = createSignal<HTMLSpanElement>();
  const size = createElementSize(ref);
  const breakpoints = useBreakpoints();
  const promptOneLineTextHeight = () => (breakpoints.md ? 25 : 22);

  const [hovering, setHovering] = createSignal(false);
  const [seeAll, setSeeAll] = createSignal(false);

  return (
    <div use:pointerHover={setHovering}>
      <Motion.div
        // {...getUnitAnimationConfig()}
        class={twMerge(
          "w-full relative dark:text-white md:pb-6 pl-2 md:pl-0 pr-2 min-h-20",
          props.class,
        )}
        classList={{
          "pt-7": (size.height || 0) <= promptOneLineTextHeight(),
          "pt-4": (size.height || 0) > promptOneLineTextHeight(),
        }}
      >
        <div class="absolute left-[-45px] md:left-[-75px] top-5  md:top-4 select-none pointer-events-none">
          <CircularAvatar
            userId={props.message.createdBy}
            size={breakpoints.md ? AvatarSize.Default : AvatarSize.Mini}
            fullName="Guest"
            class="ring-violet-500"
          />
        </div>

        <span
          ref={setRef}
          class="text-lg md:text-xl font-medium text-black dark:text-white"
        >
          {seeAll()
            ? props.message.prompt
            : truncateText(props.message.prompt, MAX_LEN)}

          <Show when={props.message.prompt.length > MAX_LEN}>
            <button
              type="button"
              onClick={() => setSeeAll((s) => !s)}
              class="text-sm text-violet-500 dark:text-violet-400 selection:text-white ml-1 select-none"
            >
              {seeAll() ? "See less" : "See more"}
            </button>
          </Show>
        </span>

        <Show when={hovering()}>
          <div class="absolute bottom-1 right-1 flex items-center gap-1">
            <CopyButton
              label="Copy Prompt"
              onClick={() => {
                stAnalytics.track("thread_prompt_copied", undefined);
              }}
              content={props.message.prompt}
            />
          </div>
        </Show>
      </Motion.div>
    </div>
  );
};

export const truncateText = (text: string, length: number) => {
  return text.length > length ? `${text.substring(0, length)}...` : text;
};
