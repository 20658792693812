import dayjs from "dayjs";
import { TbMessage, TbMinus, TbPlus, TbSearch, TbX } from "solid-icons/tb";
import {
  batch,
  type Component,
  createEffect,
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
  untrack,
} from "solid-js";
import { StIcon } from "~/components/icons";
import { useWire } from "~/wire";
import { TextField } from "@kobalte/core";
import { getRequestClient, type operations, type q } from "@repo/client";
import { createStore } from "solid-js/store";
import { StButton } from "~/components/buttons";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import { Spinner } from "~/components/Loaders";
import { debounce } from "@solid-primitives/scheduled";
import { InfiniteScroll } from "~/components/InfiniteScroll";
import { KnowledgePanelColumnTitlesRow } from "./KnowledgePanelColumnTitlesRow";
import { KnowledgePanelAssetRow } from "./KnowledgePanelAssetRow";
import { twMerge } from "tailwind-merge";

export type SortBy =
  | "last_updated"
  | "name"
  | "file_type"
  | "created_by"
  | "summary";
export type SortDir = "asc" | "desc";

export const KnowledgePanel: Component<{
  setShowKnowledge: (t: boolean) => void;
  showPowering?: boolean;
  title: string;
  class?: string;
}> = (props) => {
  const wire = useWire();
  const { setShowUploadModal } = usePromptContext();
  const client = getRequestClient(wire.services.identity.getIdentityToken);
  const isConnecting = useIsIdentityConnecting();

  const [searchControl, setSearchControl] = createSignal("");

  const [unused, setUnused] = createStore({
    sortBy: "last_updated" as SortBy,
    sortDir: "desc" as SortDir,
    search: "",
    offset: 0,
    limit: 10,
    canLoadMore: true,
  });

  const debouncedSearch = debounce((s: string) => {
    setUnused({ search: s, offset: 0 });
  }, 500);

  const onSearch = (s: string) => {
    setSearchControl(s);
    debouncedSearch(s);
  };

  const [assets, setAssets] =
    createSignal<operations.Response<q.ControlplaneSsAsset[]>>();
  const [loading, setLoading] = createSignal(false);

  createEffect(() => {
    const l = untrack(loading);
    if (isConnecting() || l) return;

    setLoading(true);
    const projectId =
      wire.services.identity.snapshot.context.identity.workingContext.projectId;

    client.controlplane
      .QueryAssetsForProject(projectId, {
        SortBy: unused.sortBy,
        Limit: unused.limit,
        Offset: unused.offset,
        SortDir: unused.sortDir,
        Search: unused.search,
      })
      .then((r) => {
        batch(() => {
          let newData: q.ControlplaneSsAsset[] = [];

          if (unused.offset === 0) {
            newData = r.data;
          } else {
            newData = [...(assets()?.data || []), ...(r.data || [])];
          }
          setAssets({
            ...r,
            data: newData,
          });
          r.data.forEach((a) => {
            wire.services.knowledge.setAssetsCache("byId", a.id, a);
          });
          setUnused("canLoadMore", unused.limit <= r.data.length);
        });
      })
      .catch((e) => {
        if (e.status === 404) return setUnused("canLoadMore", false);
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const changeUnusedSorting = (name: SortBy) => {
    if (name === unused.sortBy) {
      return setUnused("sortDir", (d) => (d === "asc" ? "desc" : "asc"));
    }
    setUnused({
      sortBy: name,
      sortDir: name === "last_updated" ? "desc" : "asc",
    });
  };

  const unusedFiltered = createMemo(() =>
    assets()?.data.filter(
      (a) =>
        !wire.services.threads.snapshot.context.activeAssets?.find(
          (b) => b.id === a.id,
        ),
    ),
  );

  const { changeKnowledge } = usePromptContext();
  const onAdd = async (asset: string) => {
    wire.services.knowledge.addQueuedKnowledgeChange(asset);
    await changeKnowledge();
    // stAnalytics.track("thread_sidebar_add_asset_clicked", { asset_id: asset });
    wire.services.knowledge.resetQueuedKnowledgeChange();
  };

  return (
    <div class={twMerge("mx-auto py-16 px-4 md:px-16", props.class)}>
      <h1 class="text-2xl font-semibold mb-5 md:mb-10 dark:text-white">
        {props.title}
      </h1>
      <div class="mb-5 md:mb-10 flex flex-wrap flex-col md:flex-row md:items-center md:justify-between gap-8">
        <TextField.Root
          value={searchControl()}
          onChange={onSearch}
          class="relative"
        >
          <TextField.Input
            class="rounded-full pl-4 pr-10 py-2 bg-white dark:bg-indigo-950 dark:text-white border border-violet-200 dark:border-indigo-800 w-full md:min-w-96 md:w-auto outline-none focus:ring-2 focus:ring-violet-400 transition-all"
            placeholder="Search your knowledge"
          />

          <Show
            when={searchControl()}
            fallback={
              <StIcon
                icon={TbSearch}
                class="absolute right-3 top-[11px] size-5 text-slate-600 dark:text-indigo-300 pointer-events-none"
              />
            }
          >
            <StButton
              simple
              icon={TbX}
              class="absolute right-3 top-[11px] size-5"
              onClick={() => {
                onSearch("");
              }}
            />
          </Show>
        </TextField.Root>

        <StButton
          icon={TbPlus}
          class="bg-violet-800 text-white hover:bg-violet-700 flex-shrink-0"
          onClick={() => setShowUploadModal(true)}
        >
          Add knowledge to My Project
        </StButton>
      </div>

      <InfiniteScroll
        isFetching={loading()}
        shouldFetchMore={unused.canLoadMore}
        fetchNext={() => {
          setUnused("offset", assets()?.data.length || 0);
        }}
      >
        {/* TABLE */}
        <div
          class="px-2 w-full relative overflow-x-auto grid dark:text-white gap-x-4 gap-y-2 grid-cols-[max-content_2.7rem_max-content_minmax(20rem,_1fr)_minmax(6rem,_max-content)_max-content_0px] md:grid-cols-[max-content_3.5rem_max-content_1fr_minmax(8rem,_max-content)_max-content_0px] md:gap-x-6 md:gap-y-3"
          classList={{
            "opacity-50 pointer-events-none": loading(),
          }}
        >
          <Show when={loading()}>
            <div class="absolute inset-0 grid place-content-center">
              <Spinner />
            </div>
          </Show>

          <Show when={props.showPowering}>
            <KnowledgePanelPoweringAssets
              search={searchControl()}
              setShowKnowledge={props.setShowKnowledge}
            />
          </Show>

          {/* UNUSED */}

          <div class="col-span-full">
            <h2 class="flex items-center gap-4 text-base md:text-lg py-5 mt-5 md:mt-10 truncate">
              You're not interacting with
            </h2>
          </div>

          <KnowledgePanelColumnTitlesRow
            sortBy={unused.sortBy}
            sortDir={unused.sortDir}
            changeSorting={changeUnusedSorting}
          />

          <For each={unusedFiltered()}>
            {(asset) => (
              <KnowledgePanelAssetRow
                search={searchControl()}
                asset={asset}
                actions={
                  <>
                    <StButton
                      onClick={() => onAdd(asset.id)}
                      label="Add to thread"
                      icon={TbPlus}
                      size="sm"
                      class=""
                    >
                      Add
                    </StButton>
                  </>
                }
              />
            )}
          </For>
        </div>
      </InfiniteScroll>
    </div>
  );
};

const KnowledgePanelPoweringAssets: Component<{
  search: string;
  setShowKnowledge: (t: boolean) => void;
}> = (props) => {
  const wire = useWire();
  const { setShowUploadModal, changeKnowledge } = usePromptContext();

  const [powering, setPowering] = createStore({
    sortBy: "last_updated" as SortBy,
    sortDir: "desc" as SortDir,
  });

  /**
   * We always have all powering assets in the FE so do sorting and filtering
   * on client-side
   */
  const poweringAssets = createMemo(() => {
    let active = [
      ...(wire.services.threads.snapshot.context.activeAssets || []),
    ];

    const search = props.search.toLowerCase();

    if (search) {
      active = active.filter(
        (a) =>
          a.originalFilename.toLowerCase().includes(search) ||
          a.displayName.toLowerCase().includes(search) ||
          a.summary.toLowerCase().includes(search),
      );
    }

    const mult = powering.sortDir === "asc" ? 1 : -1;
    if (powering.sortBy === "name") {
      return active.sort(
        (a, b) =>
          (a.displayName || a.originalFilename).localeCompare(
            b.displayName || b.originalFilename,
          ) * mult,
      );
    }

    if (powering.sortBy === "created_by") {
      return active.sort(
        (a, b) => a.userDisplayName.localeCompare(b.userDisplayName) * mult,
      );
    }
    if (powering.sortBy === "file_type") {
      return active.sort(
        (a, b) => a.contentType.localeCompare(b.contentType) * mult,
      );
    }
    if (powering.sortBy === "summary") {
      return active.sort((a, b) => a.summary.localeCompare(b.summary) * mult);
    }
    return active.sort(
      (a, b) => dayjs(a.modifiedAt).diff(dayjs(b.modifiedAt)) * mult,
    );
  });

  const changePoweringSorting = (name: SortBy) => {
    if (name === powering.sortBy) {
      return setPowering("sortDir", (d) => (d === "asc" ? "desc" : "asc"));
    }
    setPowering({
      sortBy: name,
      sortDir: name === "last_updated" ? "desc" : "asc",
    });
  };

  const onRemove = async (asset: string) => {
    wire.services.knowledge.removeQueuedKnowledgeChange(asset);
    await changeKnowledge();
    // stAnalytics.track("thread_sidebar_remove_asset_clicked", {
    //   asset_id: asset,
    // });
    wire.services.knowledge.resetQueuedKnowledgeChange();
  };

  return (
    <div
      class="grid col-span-7 relative"
      classList={{
        "grid-cols-7 max-w-[calc(100vw_-_3rem)]": poweringAssets().length === 0,
        "grid-cols-subgrid grid-rows-subgrid": poweringAssets().length !== 0,
      }}
      style={{
        "grid-row": `span ${poweringAssets().length * 2 + 3}`,
      }}
    >
      <div
        aria-hidden
        class="absolute inset-y-0 -inset-x-2 bg-violet-600/5 dark:bg-indigo-400/10 border border-violet-200 dark:border-indigo-900 rounded-lg -z-10"
      />
      <div class="col-span-full flex flex-col md:flex-row md:justify-between md:items-center md:flex-wrap gap-4 py-5">
        <h2 class="flex items-center gap-4 text-base md:text-lg truncate pl-2">
          You're interacting with
        </h2>

        <Show when={props.search || poweringAssets().length !== 0}>
          <StButton
            icon={TbMessage}
            class="max-w-72"
            onClick={() => {
              props.setShowKnowledge(false);
            }}
          >
            SmartChat with this knowledge
          </StButton>
        </Show>
      </div>

      <Show when={poweringAssets().length !== 0}>
        <KnowledgePanelColumnTitlesRow
          sortBy={powering.sortBy}
          sortDir={powering.sortDir}
          changeSorting={changePoweringSorting}
        />
      </Show>

      <Show when={!props.search && poweringAssets().length === 0}>
        <div class="grid place-content-center col-span-full h-32 text-center">
          <p>
            You're not interacting with any knowledge. <br /> You can add
            knowledge from below, or{" "}
            <button
              class="inline text-violet-700 underline underline-offset-2 dark:text-violet-200"
              type="button"
              onClick={() => setShowUploadModal(true)}
            >
              upload new knowledge
            </button>
            .
          </p>
        </div>
      </Show>

      <For each={poweringAssets()}>
        {(asset) => (
          <KnowledgePanelAssetRow
            search={props.search}
            asset={asset}
            actions={
              <>
                <StButton
                  onClick={() => onRemove(asset.id)}
                  label="Remove from thread"
                  icon={TbMinus}
                  size="sm"
                  class=""
                >
                  Remove
                </StButton>
              </>
            }
          />
        )}
      </For>
    </div>
  );
};
