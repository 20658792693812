import type { ParentComponent } from "solid-js";
import { twMerge } from "tailwind-merge";
import type { ExtendedClassProps } from "~/components/common";

export const PromptActionsBar: ParentComponent<ExtendedClassProps> = (
  props,
) => (
  <div
    class={twMerge(
      "flex flex-row flex-wrap items-center gap-2 pb-4 dark:text-slate-700",
      props.class,
    )}
  >
    {props.children}
  </div>
);
