import { stAnalytics } from "@repo/analytics";
import type { MessageInsertFileUpload } from "@repo/client";
import { TbPlus } from "solid-icons/tb";
import { createSignal, onMount, type Component } from "solid-js";
import { StButton } from "~/components/buttons";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { EditorFocusedAnimation } from "~/domains/chat/prompt/components/EditorFocusedAnimation";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useHighlightElementOnScreen } from "~/lib/useHighlightElementOnScreen";

export const InsertFileUploadUnit: Component<{
  message: MessageInsertFileUpload;
}> = () => {
  const { threadEventProps } = useThreadEventProperties();
  const { setShowUploadModal } = usePromptContext();

  const [highlight, setHighlight] = createSignal(false);
  let ref!: HTMLDivElement;

  useHighlightElementOnScreen({
    ref: () => ref,
    enabled: highlight,
    trackElementPosition: true,
    onClear: () => setHighlight(false),
  });

  onMount(() => {
    stAnalytics.track("thread_inserts_file_upload_shown", threadEventProps());

    setHighlight(true);
  });

  return (
    <div class="flex justify-center rounded -mt-5 py-5" ref={ref}>
      <div class="relative">
        <StButton
          onClick={() => {
            setHighlight(false);
            setShowUploadModal(true);
            stAnalytics.track(
              "thread_inserts_file_upload_clicked",
              threadEventProps(),
            );
          }}
          size="lg"
          icon={TbPlus}
          // class="bg-indigo-600 border-indigo-500 text-white hover:bg-indigo-700 py-10 w-full"
        >
          Upload a file to get results
        </StButton>
        <EditorFocusedAnimation focused empty />
      </div>
    </div>
  );
};
