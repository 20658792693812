import type { DropdownMenu } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import {
  TbClipboardCopy,
  TbHelpCircle,
  TbPencil,
  TbSearch,
  TbShare3,
} from "solid-icons/tb";
import { type Component, Show, createEffect, createSignal } from "solid-js";
import {
  StDropdown,
  type StDropdownMenuItem,
} from "~/components/popups/StDropdown";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import type { AuthenticatedIdentity } from "~/domains/identity/types";
import { responsePromptsData } from "~/lib/data";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";

export const TextSelectionMenu: Component<{
  rect: DOMRect | undefined;
  from: "select" | "block";
  getText: () => string;
  getEl: () => HTMLElement | undefined;
  opts?: DropdownMenu.DropdownMenuRootProps;
  content?: DropdownMenu.DropdownMenuContentProps;
  mount?: HTMLElement;
  animatedMovement?: boolean;
}> = (props) => {
  const { submitPrompt } = usePromptContext();
  const wire = useWire();
  const [portalRef, setRef] = createSignal<HTMLDivElement>();

  const { threadEventProps } = useThreadEventProperties();

  const emitTracking = (action: string) => {
    stAnalytics.track("text_selection_menu_action", {
      action,
      from: props.from,
      ...threadEventProps(),
    });
  };

  // Workaround for the kobalte dropdown menu not letting us pass a class for div in charge
  // of positioning the menu on screen
  createEffect(() => {
    if (!props.animatedMovement) return;
    const p = portalRef();
    const child = p?.childNodes[0];
    if (child instanceof HTMLElement) {
      // Using a timeout because the initial positioning of the modal is at 0,0 on the screen
      // if we add a transition class imediately it'll show it zooming around the screen to get
      // to the initial position
      setTimeout(() => {
        child.classList.add("transition-transform");
      }, 300);
    }
  });

  const clearSelection = () => getSelection()?.removeAllRanges();

  const understand = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Understand",
      icon: TbHelpCircle,
    },
    items: [
      {
        kind: "item",
        content: "Explain this to me like I'm five",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.explainLikeImFive(text),
              mentionedAssets: [],
            });
            emitTracking("explain_like_im_child");
            clearSelection();
          },
        },
      },
      {
        kind: "item",
        content: "Why does this matter?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.whyDoesThisMatter(text),
              mentionedAssets: [],
            });
            emitTracking("why_does_this_matter");
            clearSelection();
          },
        },
      },
    ],
  });

  const goDeeper = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Go deeper",
      icon: TbSearch,
    },
    items: [
      {
        kind: "item",
        content: "Get more detail on this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.getMoreDetail(text),
              mentionedAssets: [],
            });
            emitTracking("get_more_detail");
            clearSelection();
          },
        },
      },
      {
        kind: "item",
        content: "Find similar content like this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.findMoreLikeThis(text),
              mentionedAssets: [],
            });
            emitTracking("find_similar_content");
            clearSelection();
          },
        },
      },
    ],
  });

  const rewrite = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Rewrite",
      icon: TbPencil,
    },
    items: [
      {
        kind: "item",
        content: "For brevity",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForBrevity(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_brevity");
            clearSelection();
          },
        },
      },
      {
        kind: "item",
        content: "For a less technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForLessTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_less_technical_audience");
            clearSelection();
          },
        },
      },
      {
        kind: "item",
        content: "For a more technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForMoreTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_more_technical_audience");
            clearSelection();
          },
        },
      },
    ],
  });

  const share = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Share",
      icon: TbShare3,
    },
    items: [
      {
        kind: "item",
        content: "Link to this response",
        props: {
          class: "px-2",
          onSelect: () => {
            const id = props.getEl()?.getAttribute("data-block") ?? "";
            const projectId =
              wire.services.identity.snapshot.context.identity.workingContext
                .projectId;
            const threadId = wire.services.threads.snapshot.context.threadId;
            navigator.clipboard.writeText(
              window.location.origin +
                urls.thread(projectId, threadId ?? "", encodeURIComponent(id)),
            );
            emitTracking("share_via_link");
            clearSelection();
          },
        },
      },
      {
        kind: "item",
        content: "Share via email",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();

            open(
              responsePromptsData.shareViaEmailMailtoLink({
                text,
                threadName:
                  wire.services.threads.snapshot.context.label || "a thread",
                threadUrl: `https://storytell.ai${urls.thread(
                  wire.services.identity.snapshot.context.identity
                    .workingContext.projectId,
                  wire.services.threads.snapshot.context.threadId || "",
                )}`,
                userDisplayName: (
                  wire.services.identity.snapshot.context
                    .identity as AuthenticatedIdentity
                ).displayName,
              }),
            );
            emitTracking("share_via_email");
            clearSelection();
          },
        },
      },
    ],
  });

  const copy = (): StDropdownMenuItem => ({
    kind: "item",
    content: "Copy text",
    icon: TbClipboardCopy,
    props: {
      class: "pl-1",
      onSelect: () => {
        const text = props.getText();
        navigator.clipboard.writeText(text);
        emitTracking("copy_text");
        clearSelection();
      },
    },
  });

  return (
    <Show when={props.rect}>
      <StDropdown
        theme="invert"
        opts={{
          modal: false,
          preventScroll: true,
          placement: "bottom-start",
          open: !!props.rect,
          ...props.opts,
        }}
        setRef={setRef}
        mount={props.mount}
        content={props.content}
        items={[understand(), goDeeper(), rewrite(), share(), copy()]}
        trigger={{
          as: "div",
          class: "pointer-events-none fixed",
          style: {
            top: `${props.rect?.top || 0}px`,
            left: `${props.rect?.left || 0}px`,
            width: `${props.rect?.width || 0}px`,
            height: `${props.rect?.height || 0}px`,
          },
          "aria-hidden": "true",
        }}
      />
    </Show>
  );
};
